.customer {
  display: flex;
  width: 300px;
  height: 100px;
  font-size: 24px;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  /* box-shadow: rgba(149,157,165,0.2) 0px 8px 24px; */
  box-shadow: 0 4px 8px hsla(0, 0%, 0%, 0.05);


  justify-content: center;
  align-items: center;
}

.detail {
  width: 100%;
}

.drawer {
  z-index: 200;
}

.radio {
  margin: 10px;
}

.radioButton {
  font-size: 16px;
  margin-bottom: 20px;
  color: rgb(96, 96, 96);
}

.sites {
  width: 100%;
  margin-bottom: 40px;
}

.appbar {
  /* position: fixed;
  top: 0px;
  z-index: 1; */
  padding: 5px;
}

.detail-cards {
  margin-top: 100px;
}

@media only screen and (max-height: 768px) {
  .appbar {
    padding-top: 35px;
  }

  .detail-cards {
    margin-top: 123px;
  }
}