body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.map-container {
  height: 400px;
  margin-top: 10px;
}

.sitesTable .name.ascend::after {
  content: url("./images/arrow-down-s-fill.svg");
  display:inline;
  position:relative;
  top:-3px;
  left:5px
}

.sitesTable .name.descend::after {
  content: url("./images/arrow-up-s-fill.svg");
  display:inline;
  position:relative;
  top:-3px;
  left:5px;
}

.sitesTable .status.ascend::after {
  content: url("./images/arrow-down-s-fill.svg");
  display:inline;
  position:relative;
  top:-3px;
  left:5px
}

.sitesTable .status.descend::after {
  content: url("./images/arrow-up-s-fill.svg");
  display:inline;
  position:relative;
  top:-3px;
  left:5px
}

.sitesTable .level.ascend::after {
  content: url("./images/arrow-down-s-fill.svg");
  display:inline;
  position:relative;
  top:5px;
}

.sitesTable .level.descend::after {
  content: url("./images/arrow-up-s-fill.svg");
  display:inline;
  position:relative;
  top:8px;
}

.sitesTable .flood.ascend::after {
  content: url("./images/arrow-down-s-fill.svg");
  display:inline;
  position:relative;
  top:5px;
}

.sitesTable .flood.descend::after {
  content: url("./images/arrow-up-s-fill.svg");
  display:inline;
  position:relative;
  top:8px;
}

.sitesTable .lastUpdate.ascend::after {
  content: url("./images/arrow-down-s-fill.svg");
  display:inline;
  position:relative;
  top:5px;
}

.sitesTable .lastUpdate.descend::after {
  content: url("./images/arrow-up-s-fill.svg");
  display:inline;
  position:relative;
  top:8px;
}

