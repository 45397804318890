.appBar {
  position:fixed;
  top:0px;
  width:100%;
  height:48px;
  z-index:10;
}

.card {
  position:relative;
  width:200px;
  height:100px;
  background-color: rgb(255,255,255);
  border-radius:8px;
  padding:8px;
  box-sizing: border-box;
  /* box-shadow: rgba(149,157,165,0.2) 0px 8px 24px; */
  box-shadow: 0 4px 8px hsla(0, 0%, 0%, 0.05);

}

.card .name {
  /* width:200px; */
  font-size:14px;
  /* font-weight:bold; */
  text-align:left;
  /* margin:auto; */
  color:rgb(128,128,128)
}

.card .wrapper {
  position:relative;
  width:100%;
  text-align:center;
  /* margin-top:10px; */
}

.card .wrapper .reading {
  display:inline;
  font-size: 42px;
  font-weight: bold;
}

/* .card.stage {
  position:relative;
  width:200px;
  height:100px;
  background-color: rgb(255,255,255);
  border-radius:4px;
  padding:4px;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.26);
} */

/* .card.stage .wrapper {
  position:relative;
  width:100%;
  text-align:center;
  margin-top:10px;
} */

/* .card.stage .wrapper .reading {
  display:inline;
  font-size: 32px;
  font-weight: bold;
} */

.chart {
  margin-left:10px;
  margin-right:10px;
  margin-top:10px;
  border-radius:8px;
  background-color:rgb(255,255,255);
  /* box-shadow: rgba(149,157,165,0.2) 0px 8px 24px; */
  box-shadow: 0 4px 8px hsla(0, 0%, 0%, 0.05);
}

.drawer {
  position:fixed;
  height: 100%;
  width: 250px;
  z-index:20;
  top: 0px;
  background-color: white;
  padding-top:10px;
  transition: 0.5s;
  box-shadow: 1px 3px 10px #9E9E9E;
}

.menuButton {
  width:40px;
  height:40px;
  border-radius:50%;
  background-color:rgba(240,240,240,0.3);
}

.menu.item.disabled {
  display:block;
  font-size:21px;
  line-height:40px;
  height:40px;
  padding-left:10px;
  background-color:rgba(192,192,192,0.3);
}

.menu.item {
  display:block;
  font-size:21px;
  height:40px;
  line-height:40px;
  padding-left:10px;
  color:rgb(0,0,0);
  background-color: transparent;
}

.menu.item:hover {
  background-color: rgba(192,192,192,0.3);
}

.menu.subTitle {
  font-size: 14px;
  color: rgb(128,128,128);
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.sites.table {
  width:90%;
  background-color: rgb(240,240,240);
}

table .lastUpdate {
  font-size:16px;
  font-weight:bold;
  text-align: left;
  cursor: pointer;
  padding-right: 5px;
}

table .lastUpdate.ascend {
  background-image: url("../images/arrow-up-s-fill.svg");
  background-repeat: no-repeat;
  background-position: 90px;
}

table .lastUpdate.descend {
  background-image: url("../images/arrow-down-s-fill.svg");
  background-repeat: no-repeat;
  background-position: 90px;
}
 
table .name {
  font-size:16px;
  font-weight:bold;
  text-align: left;
  cursor: pointer;
  padding-right: 5px;
}

table .name.ascend {
  background-image: url("../images/arrow-up-s-fill.svg");
  background-repeat: no-repeat;
  background-position: 50px;
}

table .name.descend {
  background-image: url("../images/arrow-down-s-fill.svg");
  background-repeat: no-repeat;
  background-position: 50px;
}

table .reading {
  font-size:16px;
  font-weight:bold;
  text-align: left;
  cursor: pointer;
  padding-right: 5px;
}

table .reading.ascend {
  background-image: url("../images/arrow-up-s-fill.svg");
  background-repeat: no-repeat;
  background-position: 75px;
}

table .reading.descend {
  background-image: url("../images/arrow-down-s-fill.svg");
  background-repeat: no-repeat;
  background-position: 75px;
}

table .state {
  font-size:16px;
  font-weight:bold;
  text-align: left;
  cursor: pointer;
  padding-right: 5px;
}

table .state.ascend {
  background-image: url("../images/arrow-up-s-fill.svg");
  background-repeat: no-repeat;
  background-position: 45px;
}

table .state.descend {
  background-image: url("../images/arrow-down-s-fill.svg");
  background-repeat: no-repeat;
  background-position: 45px;
}

table .status {
  font-size:16px;
  font-weight:bold;
  text-align: left;
  cursor: pointer;
  padding-right: 5px;
}

table .status.ascend {
  background-image: url("../images/arrow-up-s-fill.svg");
  background-repeat: no-repeat;
  background-position: 200px;
}

table .status.descend {
  background-image: url("../images/arrow-down-s-fill.svg");
  background-repeat: no-repeat;
  background-position: 200px;
}

.table {
  width:90%;
  margin:auto;
  margin-top:165px;
  margin-bottom:20px;
  background:white;
  border-radius:4px;
  /* box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.26); */
  box-shadow: rgba(149,157,165,0.2) 0px 8px 24px;
}

.unit {
  display:inline;
  font-size:16px;
  margin-left:5px;
}