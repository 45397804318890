@media only screen and (max-width: 2600px) {
    .home .grid {grid-template-columns: auto auto auto auto auto}

    #appBar #title {font-size:24px; font-weight:bold}
    .sites .cards {grid-template-columns: auto auto auto auto auto}

    .sitesTable .rows {grid-template-columns: auto auto auto auto auto}
    #SearchField {width: 50%}

    .maxbotix .cards {grid-template-columns: auto auto auto}
    .maxbotix #appBar #title {font-size: 28px;}

    .radar #appBar #title {font-size: 28px;}
    .radar .cards {grid-template-columns: auto auto auto auto}

    .radarWS100 #appBar #title {font-size: 28px;}

    .detail .cards {grid-template-columns: auto auto auto auto auto}
    .detail #appBar #title {font-size: 28px;}

    .radarWS100 .cards {grid-template-columns: auto auto auto}
    .WS600 .cards {grid-template-columns: auto auto auto auto auto}
}

@media only screen and (max-width: 2000px) {
    .home .grid {grid-template-columns: auto auto auto auto}

    #appBar #title {font-size:24px; font-weight:bold}
    .sites .cards {grid-template-columns: auto auto auto auto}

    .sitesTable .rows {grid-template-columns: auto auto auto auto auto}
    #SearchField {width: 50%}

    .maxbotix .cards {grid-template-columns: auto auto auto}
    .maxbotix #appBar #title {font-size: 28px;}

    .radar #appBar #title {font-size: 28px;}
    .radar .cards {grid-template-columns: auto auto auto auto}

    .radarWS100 #appBar #title {font-size: 28px;}

    .detail .cards {grid-template-columns: auto auto auto auto auto}
    .detail #appBar #title {font-size: 28px;}

    .radarWS100 .cards {grid-template-columns: auto auto auto}
    .WS600 .cards {grid-template-columns: auto auto auto auto auto}
}

@media only screen and (max-width: 1400px) {
    .home .grid {grid-template-columns: auto auto auto}

    .sites .cards {grid-template-columns: auto auto auto auto}
    #SearchField {width: 50%}

    .maxbotix .cards {grid-template-columns: auto auto auto}

    .radar .cards {grid-template-columns: auto auto auto auto}

    .detail .cards {grid-template-columns: auto auto auto}
    .radarWS100 .cards {grid-template-columns: auto auto auto}
    .WS600 .cards {grid-template-columns: auto auto auto auto auto}
}

@media only screen and (max-width: 1000px) {
    .home .grid {grid-template-columns: auto auto}
    .sites .cards {grid-template-columns: auto auto}
    #SearchField {width: 50%}

    .maxbotix .cards {grid-template-columns: auto auto}

    .radar .cards {grid-template-columns: auto auto}

    .detail .cards {grid-template-columns: auto auto}

    .RadarWS100 .cards {grid-template-columns: auto auto}
    .WS600 .cards {grid-template-columns: auto auto}
}

@media only screen and (max-width: 800px) {
    .home .grid {
      grid-template-columns: auto auto;
    }

    .sites .cards {grid-template-columns: auto auto}
    #SearchField {
        width: 50%;
    }

    .maxbotix .cards {grid-template-columns: auto auto;}

    .radar .cards {grid-template-columns: auto auto}

    .detail .cards {grid-template-columns: auto auto;}
    .radarWS100 .cards {grid-template-columns: auto auto}
    .WS600 .cards {grid-template-columns: auto auto}
}

@media only screen and (max-width: 600px) {
    .home .grid {
      grid-template-columns: auto;
    }

    .sites .cards {
        grid-template-columns: auto;
    }

    #SearchField {width: 80%;}

    .maxbotix .cards {
        grid-template-columns: auto;
    }

    .radar .cards {
        grid-template-columns: auto;
    }

    .detail .cards {grid-template-columns: auto}
    .radarWS100 .cards {grid-template-columns: auto}
    .WS600 .cards {grid-template-columns: auto}
}

@media only screen and (max-width: 450px) {
    .home .grid { grid-template-columns: auto}
    #appBar #title {font-size: 24px}
    .sites .cards {grid-template-columns: auto}
    #SearchField {width: 90%}
    .maxbotix .cards  {grid-template-columns: auto}
    .maxbotix #appBar #title {
        font-size: 24px;
        width:220px;
    }
    .radar .cards {grid-template-columns: auto}
    .detail .cards  {grid-template-columns: auto}
    .radarWS100 .cards {grid-template-columns: auto}
    .WS600 .cards {grid-template-columns: auto}
}



